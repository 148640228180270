var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading)?_c('div',[_c('v-card',{staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"greyBase","dark":"","extended":!_vm.$vuetify.breakpoint.mobile,"flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("Stock Overview")])]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":"","outlined":"","rounded":"","color":"blue"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.tab == 0 ? 'blue' : 'primaryText'}},[_vm._v("widgets")]),_c('b',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Dashboard")])],1),_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":_vm.tab == 1 ? 'blue' : 'primaryText'}},[_vm._v("grading")]),_c('b',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Allocate Orders")])],1),_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.tab == 2 ? 'blue' : 'primaryText'}},[_vm._v("insights")]),_c('b',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("Inventory Overview")])],1),_c('v-btn',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":_vm.tab == 3 ? 'blue' : 'primaryText'}},[_vm._v("format_list_bulleted")]),_c('b',{staticStyle:{"text-transform":"none","color":"var(--v-primaryText-base)"}},[_vm._v("All Inventory")])],1)],1)],1),_c('v-card',{staticClass:"mx-auto",style:({
            'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '0px',
            'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
        }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
                height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                'overflow-y': 'auto',
            })},[(_vm.tab == 0)?_c('Dashboard',{staticStyle:{"height":"100%"}}):(_vm.tab==1)?_c('OrderAllocate',{staticStyle:{"height":"100%"}}):(_vm.tab==2)?_c('Inventory',{staticStyle:{"height":"100%"}}):(_vm.tab==3)?_c('RawInventory',{staticStyle:{"height":"100%"}}):_vm._e()],1)],1)],1)],1):_c('div',{staticClass:"text-center",staticStyle:{"height":"90vh","padding-top":"0","margin-top":"0","background":"transparent"}},[_c('valhalla-loading',{attrs:{"height":'85vh'}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }