<template>
    <div>
        <div v-if="!loading">
            <AssignStock
        :orders="orders"
        @close="$emit('refreshStock')"
      />
        </div>
        <div v-else>
            <valhalla-loading height="75vh" />
          </div>
    </div>
</template>
<script>
import AssignStock from "../AssignStock.vue";

export default {
    components: {
        AssignStock
    },
    data:()=>({
        loading: false,
        orders: []
    }),
    created(){
        this.getOrders()
    },
    methods: {
        async getOrders(){
            this.loading = true
            this.orders = await this.$API.getBasicAvailableOrders({
                // params: {
                //     orderStatus: 'OPEN'
                // }
            })
            this.loading = false
        }
    }
}
</script>