<template>
    <div>
        <div v-if="!loading">
            <v-card flat style="background-color: var(--v-background-base) !important">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                <v-toolbar-title>
                    <b class="mr-3">Stock Overview</b>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn-toggle
          mandatory
          outlined
          v-model="tab"
          rounded
          color="blue"
        >
        <v-btn small outlined>
            <v-icon left small :color="tab == 0 ? 'blue' : 'primaryText'"
              >widgets</v-icon
            >
            <b style="text-transform: none; color: var(--v-primaryText-base)">Dashboard</b>
          </v-btn>
          <!-- <v-btn small outlined>
            <v-icon left small :color="tab == 1 ? 'blue' : 'white'"
              >attach_money</v-icon
            >
            <b style="text-transform: none; color: white">Orders</b>
          </v-btn> -->
          <v-btn small outlined>
            <v-icon left small :color="tab == 1 ? 'blue' : 'primaryText'"
              >grading</v-icon
            >
            <b style="text-transform: none; color: var(--v-primaryText-base)">Allocate Orders</b>
          </v-btn>
          <!-- <v-btn small outlined>
            <v-icon small left :color="tab == 3 ? 'blue' : 'white'"
              >place</v-icon
            >
            <b style="text-transform: none; color: white">Network Map</b>
          </v-btn> -->
          <v-btn small outlined>
            <v-icon small left :color="tab == 2 ? 'blue' : 'primaryText'"
              >insights</v-icon
            >
            <b style="text-transform: none; color: var(--v-primaryText-base)">Inventory Overview</b>
          </v-btn>

          <v-btn small outlined>
            <v-icon small left :color="tab == 3 ? 'blue' : 'primaryText'"
              >format_list_bulleted</v-icon
            >
            <b style="text-transform: none; color: var(--v-primaryText-base)">All Inventory</b>
          </v-btn>
          
        </v-btn-toggle>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">
                <Dashboard v-if="tab == 0" style="height: 100%"/>
                <!-- <Order v-else-if="tab==1" style="height: 100%"/> -->
                <OrderAllocate v-else-if="tab==1" style="height: 100%"/>
                <!-- <NetworkMap v-else-if="tab==3" :stock="stock" style="height: 100%"/> -->
                <Inventory v-else-if="tab==2" style="height: 100%"/>
                <RawInventory v-else-if="tab==3" style="height: 100%"/>
                </v-card-text>
                </v-card>
                </v-card>
        </div>
        <div v-else style="
                height: 90vh;
                padding-top: 0;
                margin-top: 0;
                background: transparent;
              " class="text-center">
            <valhalla-loading :height="'85vh'" />
        </div>
      
    </div>
</template>
<script>
import Inventory  from './OverviewTabs/Inventory.vue'
// import NetworkMap  from './OverviewTabs/NetworkMap.vue'
import Dashboard  from './Dashboard.vue'
// import Order  from '../../views/Order.vue'
import OrderAllocate from './OverviewTabs/OrderAllocate.vue'
import RawInventory from './OverviewTabs/RawInventory.vue'
export default {
    name: "StockOverview",
    components: {
        Inventory,
        // NetworkMap,
        Dashboard,
        // Order,
        OrderAllocate,
        RawInventory
    },
    data: ()=>({
        loading: false,
        stock: [],
        tab: null,
    }),
    created(){
        // this.getStockOverview()
    },
    methods: {
        async getStockOverview(){
            this.loading = true
            this.stock = await this.$API.getStockOverview()
            this.stock.forEach((x, i)=>{
                x.key = (1+1) *1000
            })
            this.loading = false
        }
    },
}
</script>